<template>
<div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="roles" sort-by="calories" class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Produtos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn large small outlined icon @click="sendUpdateProduct" :loading="loadingUpdateProduct" :disabled="loadingUpdateProduct">
                    <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

            </v-toolbar>
        </template>
        <template v-slot:item.sale_price="{ item }">
            {{formatMoney(item.sale_price) }}
        </template>
        <template v-slot:item.active="{ item }">
            <v-switch  @click="sendActive(item)" v-model="item.active" true-value="yes" false-value="not" hide-details></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">

            <v-btn class="" icon color="secondary " @click="showItem(item)">
                <v-icon   >
                    {{ icons.mdiMagnifyPlusOutline  }}
                </v-icon>
            </v-btn>

        </template>

        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>
    <!-- DIALOG VISUALIZAR PRODUTO -->
    <v-dialog fullscreen v-model="dialog">
        <v-card>
            <BarTitleDialog title="Visualizar Produto" @close="dialog = false" />
            <v-card-text>
                <v-row>
                    <v-col cols="3">
                        <v-text-field readonly v-model="editedItem.code" outlined label='Codigo'></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly v-model="editedItem.description" outlined label='Descrição'></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <vuetify-money readonly v-model="editedItem.unit_cost" outlined label="Custo Unitário" />
                    </v-col>
                    <v-col>
                        <vuetify-money readonly v-model="editedItem.sale_price" class="ml-6" outlined label="Preço de Venda" />
                    </v-col>
                    <v-col>
                        <vuetify-money readonly v-model="editedItem.contribution_margin" class="ml-6" outlined label="Margem de Contribuição" />
                    </v-col>
                    <v-col>
                        <vuetify-money readonly v-model="editedItem.costs_contribution_margin_percent" class="ml-6" outlined label="Porcentagem de Margem de Contribuição" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field readonly v-model="editedItem.ncm" outlined label='NCM'></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field readonly v-model="editedItem.cfop" class="ml-6" outlined label='CFOP'></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-card-actions>
              <v-row>
                <v-col class="text-right">
                  <v-btn x-large color="primary">Salvar</v-btn>
                </v-col>
              </v-row>

            </v-card-actions> -->
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import NoDataTable from '@/components/NoDataTable.vue'
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus,
    mdiUpdate,
    mdiEyeOutline,
    mdiMagnifyPlusOutline
} from '@mdi/js'
import {
    mapActions
} from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
    components: {
        BarTitleDialog,
        NoDataTable,
    },
    data: () => ({
        loadingUpdateProduct: false,
        loadingTable: false,
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        dialog: false,
        loading: false,
        headers: [{
                text: 'ID',
                align: 'start',
                value: 'id',
            },
            {
                text: 'CODIGO',
                value: 'code'
            },
            {
                text: 'DESCRIÇÃO',
                value: 'description'
            },
            {
                text: 'PREÇO DE VENDA',
                align: 'right',
                value: 'sale_price'
            },
            {
                text: 'ATIVO',
                align: 'center',
                value: 'active'
            },
            {
                text: '',
                align: 'end',
                value: 'actions',
                sortable: false,
            },
        ],
        roles: [],
        editedIndex: -1,
        editedItem: {
            group_products_id: '',
            units_id: '',
            code: '',
            description: '',
            unit_cost: '',
            sale_price: '',
            contribution_margin: '',
            costs_contribution_margin_percent: '',
            is_product: '',
            ncm: '',
            cfop: '',
        },
        defaultItem: {
            name: '',
        },
        options: {},
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
            mdiUpdate,
            mdiEyeOutline,
            mdiMagnifyPlusOutline

        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Novo Perfil' : 'Editar Perfil'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options: {
            handler() {
                this.initialize()
            },
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        ...mapActions('product', ['getAll', 'updateProduct', 'storeProductActive']),
        initialize() {
            this.loadingTable = true

            const {
                page,
                itemsPerPage
            } = this.options

            const payload = {
                itemsPerPage: itemsPerPage || 10,
                page,
                search: this.search,
            }

            this.getAll(payload)
                .then(response => {
                    this.roles = response.data.data
                    this.totalStagesPaginate = response.data.total
                    this.numberOfPagesPaginate = response.data.last_page
                    this.currentPagePaginate = response.data.current_page
                })
                .finally(() => {
                    this.loadingTable = false
                })
        },
        sendActive(item) {

            this.storeProductActive(item).then(() => {
                this.$toast.success('Produto salvo')
            }).finally(() => {

            })

        },
        sendUpdateProduct() {
            this.loadingUpdateProduct = true
            this.updateProduct().then((response) => {
                this.roles = response.data.data
                this.totalStagesPaginate = response.data.total
                this.numberOfPagesPaginate = response.data.last_page
                this.currentPagePaginate = response.data.current_page
            }).finally(() => {
                this.loadingUpdateProduct = false
            })
        },
        showItem(item) {
            this.editedItem = item
            this.dialog = true
        },
        formatMoney(value) {
            value = parseFloat(value)
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },

    },
}
</script>
